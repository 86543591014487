/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Mixins */
@import 'compass-mixins/lib/compass/css3';

body {
  background: #e4e6eb;
  overflow: hidden;
  min-width: 300px;
  font-size: 16px !important;
}

html {
  min-width: 1200px;
}

.t {
  height: 100%;
  width: 100%;
  display: table;
}

.v {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.padding-0 {
  padding: 0 !important;
}

.development-mode {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: rgba($color: white, $alpha: 0.8);
  box-sizing: border-box;
  color: black;
  text-align: center;
  z-index: 10;
  img {
    height: 90px;
    opacity: 0.4;
  }
  p {
    font-size: 17px;
  }
}

.height-100 {
  height: 100%;
}
.overflow-hidden {
  overflow: hidden;
}

.center-align {
  display: block;
  text-align: center;
}

ion-button {
  --border-radius: 30px;
}

ion-badge {
  font-size: 11px;
  --padding: 0;
  top: 11px;
  width: 17px;
  height: 17px;
}

a {
  cursor: pointer;
}

.popup {
  max-width: 600px;
  width: 100%;
  @include inline-block(top);
  background: #fff;
  &__header {
    position: relative;
    height: 56px;
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
  }
  &__title {
    padding-left: 20px;
    text-align: left;
    line-height: 56px;
  }
  &__close {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: #e4e6eb;
    text-align: center;
    box-sizing: border-box;
    ion-icon {
      margin-top: 7px;
      font-size: 22px;
    }
  }
  &__content {
    padding: 15px 40px 25px;
    ion-button {
      --padding-start: 30px;
      --padding-end: 30px;
      margin-top: 25px;
    }
  }
}

.single-block {
  position: relative;
  --padding-top: 20px;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
  --background: #f0f2f5;
  height: 100%;
  box-sizing: border-box;
  &--no-padding {
    --padding-top: 1px;
    --padding-start: 1px;
    --padding-end: 1px;
    --padding-bottom: 2px;
    --background: white;
  }
  &__container {
    position: relative;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    .pages {
      position: absolute !important;
    }
  }
}

.content-block {
  @include inline-block(top);
  position: relative;
  width: 100%;
  background: #fff;
  @include border-radius(10px);
  @include box-shadow(
    0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.08)
  );
  overflow: hidden;
  padding-top: 57px;
  &_curators {
    @include box-shadow(1px 1px 2px 2px rgba(45, 211, 111, 0.5));
  }
  &_absolute {
    position: absolute;
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
  }
  &__content-wrapper {
    &_relative {
      position: relative;
    }
    height: 100%;
  }
}
.content-block-header {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 20px;
  width: 100%;
  height: 57px;
  background: #f8f8f8;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  z-index: 2;
  &__container {
    margin-right: 130px;
  }
  &__title {
    @include inline-block(top);
    font-size: 19px;
    font-weight: 600;
    text-decoration: none;
    margin-right: 20px;
    float: left;
    .go-back {
      @include inline-block(top);
      margin: 12px 15px 0 0;
      width: 32px;
      height: 32px;
      @include border-radius(50%);
      text-align: center;
      background: #e4e6eb;
      ion-icon {
        margin-top: 6px;
        font-size: 20px;
      }
    }
    span {
      line-height: 57px;
    }
  }
  &__searchbar {
    width: 100%;
    float: left;
    @include inline-block(top);
    display: inline;
    vertical-align: top;
    margin-top: 6px;
    border: 1px solid #ddd;
    border-radius: 30px;
    padding: 0;
    --border-radius: 30px;
    --box-shadow: none;
  }
  &__date-period {
    line-height: 51px;
    margin-right: 15px;
  }
  &__menu {
    position: absolute;
    right: 0;
    top: 0;
  }
  &__button {
    @include inline-block(top);
    vertical-align: top;
    padding-top: 9px;
    margin: 7px 15px 0 0;
    width: 42px;
    height: 42px;
    @include border-radius(50%);
    background: #e4e6eb;
    text-align: center;
    @include box-sizing(border-box);
    ion-icon {
      font-size: 22px;
    }
    &_refresh {
      opacity: 0.9;
      transition: 0.3s;
      &:active {
        opacity: 0.3;
        transform: rotate(360deg);
      }
    }
    &_create {
      background: #f27625;
      color: #fff;
    }
    &-icon {
      font-size: 22px;
    }
  }
}
.content-block-main {
  height: 100%;
  background: #fff;
  overflow: auto;
  box-sizing: border-box;
  &_padding {
    padding: 20px;
  }
  ion-col {
    padding: 8px 0;
  }
}

.vh100m400 {
  height: calc(100vh - 400px);
  margin-left: 1px;
  width: calc(100% - 2px);
}
.relative {
  position: relative;
}
.mh160 {
  min-height: 160px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.pie {
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background-origin: border-box;
  background-image: conic-gradient(
    #24ab00 calc(3.6deg * var(--segment1)),
    red 0 calc(3.6deg * var(--segment2)),
    #eee 0
  );
}

.square {
  @include inline-block(top);
  margin: 3px 5px 0 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  &_positive {
    background: #24ab00;
  }
  &_negative {
    background: red;
  }
  &_process {
    background: #f27625;
  }
  &_neutral {
    background: #eeeeee;
    border: 1px solid #ddd;
  }
  &_cancel {
    background: #000;
  }
}

.revenue {
  background: #24ab00;
  color: #ffffff;
}
.expenses {
  background: #0070ef;
  color: #ffffff;
}
.profit-negative {
  background: red;
  color: #ffffff;
  top: 65%;
}
.profit-positive {
  background: green;
  color: #ffffff;
}

.link {
  color: #f27625;
}

.normal {
  color: inherit;
}

.hint-menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 180px;
  border-radius: 6px;
  padding: 8px 16px 8px;
  background: #fff;
  @include box-shadow(
    0 0 6px 0 rgba(0, 0, 0, 0.2),
    0 0 6px 0 rgba(0, 0, 0, 0.2),
    0 0 6px 0 rgba(0, 0, 0, 0.2)
  );
  z-index: 10;
  ion-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    font-size: 18px;
    opacity: 0.5;
  }
  a {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 10px 0 10px 26px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #000;
    &:last-child {
      border: none;
    }
  }
}

.infinite-loading {
  margin-bottom: 0;
}

.tender-item-table {
  span {
    font-weight: 400;
    opacity: 0.7;
  }
  font-weight: 500;
}

@media screen and (max-width: 1400px) {
  .primary-block {
    width: 50% !important;
  }
  .secondary-block {
    left: 50% !important;
  }
  .widgets {
    &__col {
      &_1 {
        width: 100% !important;
      }
      &_2 {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .header__search-bar {
    height: 44px !important;
  }

  .iphone {
    .header__search-bar {
      height: 38px !important;
    }
  }
  .primary-block__content {
    top: env(safe-area-inset-top) !important;
    border-top: 1px solid #eee;
  }
  .tender-page {
    --padding-bottom: 20px !important;
  }
  .tender-item {
    grid-template-columns: 180px 1fr !important;
    grid-gap: 20px;
  }
  .primary-block {
    width: 100% !important;
  }
  .secondary-block {
    display: none;
    &_mobile-fullscreen {
      display: block !important;
      z-index: 7;
      left: 0 !important;
      right: 0 !important;
    }
    &_ios {
      top: 40px !important;
      bottom: 84px !important;
    }
  }
  .chat {
    top: env(safe-area-inset-top) !important;
  }
  .chat,
  .chat__messages-and-contacts {
    width: 100% !important;
  }
  .chat_mini {
    display: none !important;
  }
  .chat-conversation {
    position: absolute !important;
    width: 100% !important;
    top: 0;
  }
  .tabs_www-vers {
    display: none !important;
  }
  .tabs_mobile-vers {
    display: inherit !important;
  }
  .apple-header {
    display: block;
    position: relative;
    height: env(safe-area-inset-top);
    background: #f8f8f8;
    z-index: 1;
  }
}
@media screen and (max-width: 700px) {
  .header {
    &__button {
      &--change-view {
        display: none !important;
      }
    }
    &__container {
      &_cargo-tenders-tab {
        padding-right: 185px !important;
      }
    }
  }
  .warehouse-item {
    &__info {
      width: 100% !important;
    }
    &__slider {
      margin-top: 20px !important;
      position: relative !important;
      top: auto !important;
      bottom: auto !important;
      left: auto !important;
      right: auto !important;
      width: 100% !important;
      height: 300px !important;
    }
    &__menu {
      position: relative !important;
      top: auto !important;
      margin-top: 20px !important;
      &-link {
        display: inline-block !important;
        margin-bottom: 0 !important;
        margin-right: 10px !important;
      }
    }
  }
  .tender-page {
    &__contact-avatar {
      position: relative !important;
      left: auto !important;
      top: atuo !important;
      text-align: center;
    }
    &__contact-info {
      margin: 0 !important;
    }
  }
  .warehouse-page {
    &__contact-avatar {
      position: relative !important;
      left: auto !important;
      top: atuo !important;
      text-align: center;
    }
    &__contact-info {
      margin: 0 !important;
    }
  }
}
.header {
  &__container {
    .close-searchbar {
      display: none;
    }
  }
}
@media screen and (max-width: 600px) {
  .header {
    &__container {
      .close-searchbar {
        display: block;
        position: absolute;
        top: 16px;
        right: 28px;
        z-index: 11;
        ion-icon {
          font-size: 24px;
        }
      }
      &_cargo-tenders-tab,
      &_transport-tenders-tab {
        padding: 0 !important;
        .header__search-bar {
          position: absolute;
          width: 44px !important;
          background: white;
          z-index: 10;
          transition: 0.3s;
          .searchbar-search-icon {
            left: 11px;
            transition: 0.3s;
          }
          input {
            display: none !important;
          }
          &--opened {
            width: auto !important;
            right: 15px;
            transition: 0.3s;
            input {
              display: inline-block !important;
            }
            .searchbar-search-icon {
              left: 16px;
            }
          }
        }
      }
      &_cargo-tenders-tab {
        .header__search-bar {
          left: 65px;
        }
      }
      &_transport-tenders-tab {
        .header__search-bar {
          left: 15px;
        }
      }
    }
  }
  .iphone {
    .header {
      &__container {
        &_cargo-tenders-tab {
          .header__search-bar {
            transform: scale(1.16666666667);
            width: 38px !important;
            .searchbar-search-icon {
              left: 7px;
              margin: 0 !important;
            }
            &--opened {
              width: 300px !important;
              .searchbar-search-icon {
                left: 6px;
              }
            }
          }
        }
        &_transport-tenders-tab {
          .header__search-bar {
            transform: scale(1.16666666667);
            width: 38px !important;
            .searchbar-search-icon {
              left: 7px;
              margin: 0 !important;
            }
            &--opened {
              left: 39px !important;
              width: 349px !important;
              .searchbar-search-icon {
                left: 6px;
              }
            }
          }
        }
        &_cargo-tenders-tab {
          .header__search-bar {
            left: 64px;
            &--opened {
              left: 90px;
            }
          }
        }
      }
    }
  }
  .tender-item {
    &__route {
      margin-right: 0 !important;
      border-right: none !important;
    }
    &__menu {
      position: relative !important;
      top: 0;
      right: 0;
      margin-top: 20px;
      width: 100% !important;
      box-sizing: border-box;
      &-link {
        display: inline-block !important;
        margin: 0 10px 10px 0 !important;
      }
    }
  }
  .transport-list-offer__item {
    padding-right: 0 !important;
  }
  .transport-list-offer__actions {
    position: relative !important;
    top: auto !important;
    width: 100%;
  }
  .transport-list-offer__actions-item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .tender-item {
    grid-template-columns: 1fr !important;
    grid-gap: 0;
    .company {
      padding: 0 !important;
    }
    .cargo-points {
      margin: 20px 0 10px !important;
    }
    &__route {
      border-left: none !important;
    }
    &__menu {
      padding-left: 0 !important;
    }
  }
  .header {
    &__button {
      margin-right: 6px !important;
      &:last-child {
        margin-right: 15px !important;
      }
    }
    .toggle-button {
      margin-right: 6px !important;
    }
    label {
      .header__button {
        margin-right: 6px !important;
      }
    }
  }
}

ionic-selectable.ng-touched.ng-invalid .ionic-selectable-cover {
  border-bottom: 2px solid #eb445a;
}

.alert-wrapper {
  width: 100% !important;
}

.max-width-400 {
  max-width: 400px;
}

.normal {
  color: inherit;
}

.mobile-show {
  display: none !important;
}

.mobile-hidden {
  display: block !important;
}

@media screen and (max-width: 1200px) {
  .mobile-show {
    display: block !important;
  }

  .mobile-hidden {
    display: none !important;
  }
}

.iphone ~ * {
  .popup {
    margin-top: 38px;
  }
}

mwl-calendar-event-actions a {
  color: var(--bs-link-color);
}

.height-760 {
  --height: 760px;
}

ion-toast {
  --white-space: pre;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
